.App {
  text-align: center;
}

.form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  background-color: lightgrey;
  border: 2px solid #000;
  box-shadow: 24px;
}

.list-container {
  height: 150px; /* Adjust the height as needed */
  overflow-y: auto;
}

.scrollable-list {
  padding-left: 20px;
  list-style: none;
  margin: 0;
}
