.profile-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  margin-top: 20px;
}

.profile-row {
  display: flex;
  align-items: center; /* Align items vertically in the middle */
}

.profile-row > * {
  margin-right: 20px; /* Add some spacing between the components */
}

body {
  background: linear-gradient(to bottom, #708090, #1c1e1f);
  background-attachment: fixed;
}

.white-box {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.white-box > * {
  display: inline-block; /* Make the child elements inline */
  margin-right: 20px; /* Add some spacing between the child elements */
}

.profile-section {
  order: 2; /* Change the order to stack below */
}

.tournaments-section {
  order: 1; /* Change the order to stack above */
}
