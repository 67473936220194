.userPFP-container {
    width: 250px;
    height: 250px;
    border: 5px solid black;
    border-radius: 50%;
    overflow: hidden; /* Ensure the image is clipped to the container */
  }
  
  .userPFP {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  