.navbar {
  background-color: #333;
  padding: 10px;
  /* border-radius: 20px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.font-unbounded {
  color: #fff;
  margin-left: 10px;
}

.navbar-buttons button {
  background-color: #333;
  color: #fff;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 18px;
  margin-right: 10px;
  border: none;
  cursor: pointer;
}

.navbar-buttons button:hover {
  background-color: #555;
}

.nav-link {
  text-decoration: none;
  color: #fff;
}
  